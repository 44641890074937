const lightTheme = {
  headerHeight: 120,
  fixedHeaderHeight: 80,
  jumbotronHeight: 550,
  maxPageWidth: 1100,
  backgroundColor: '#FFFFFF',
  primaryColor: '#C27500',
  secondaryColor: '#734F1A',
  tertiaryColor: '#60BEBF',
  textColor: '#363636',
  lightColor: '#eeece7',
  darkColor: '#24211e',
  primaryFont: 'Catamaran, sans-serif',
  secondaryFont: 'Merriweather Sans, sans',
}

module.exports = lightTheme;